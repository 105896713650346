import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import securityUtils from '@/utils/security'

Vue.use(Vuex)


const createStore = () => {
    return new Vuex.Store({
        plugins: [createPersistedState()],

        state: {
            barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
            // barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-2.jpg',
            drawer: null,
            authorization: null,
            isLoggedIn: null
        },

        mutations: {
            SET_BAR_IMAGE(state, payload) {
                state.barImage = payload
            },
            SET_DRAWER(state, payload) {
                state.drawer = payload
            },
            setAuthorization(state, auth) {
                state.authorization = auth
            },
            setIsLoggedIn(state) {
                state.isLoggedIn = securityUtils.isLoggedIn()
            }
        },
        getters: {
            getAuthorization: (state) => {
                return state.authorization
            },
            getUserId: (state) => {
                if (state.authorization !== null) {
                    return state.authorization.userId
                }
            },
            getIsLoggedIn: (state) => {
                return state.isLoggedIn
            },
            getUserStatusId: (state) => {
                if (state.authorization !== null) {
                    return state.authorization.statusId
                }
            }
        },
        actions: {}
    })
}


export default createStore
