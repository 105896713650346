import moment from 'moment-timezone';
import Vue from "vue";

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment.utc(String(value)).local().format('MM/DD/YYYY')
    }

    return value
})

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment.utc(String(value), 'YYYY-MM-DDTHH:mm:ss').local().format('MM/DD/YYYY HH:mm')
    }

    return value
})

Vue.filter('formatDateTimeWithSecs', function(value) {
    if (value) {
        return moment.utc(String(value), 'YYYY-MM-DDTHH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss')
    }

    return value
})

Vue.filter('formatCurrencyUSD', function(value) {
    const numValue = Number.parseFloat(value)
    if (Number.isNaN(numValue)) {
        return value
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })
    return formatter.format(numValue)
})

Vue.filter('formatCurrency', function(value) {
    const numValue = Number.parseFloat(value)
    if (Number.isNaN(numValue)) {
        return value
    }
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
    })
    return formatter.format(numValue)
})