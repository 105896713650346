import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'home',
      path: '/',
      component: () => import('@/views/pages/Login'),
      meta: {
        title: 'Account Login'
      }
    },
    {
      name: 'account',
      path: '/account/view',
      component: () => import('@/views/pages/ViewAccount'),
      meta: {
        title: 'Account'
      }
    },
    {
      name: 'update_account',
      path: '/account/update',
      component: () => import('@/views/pages/UpdateAccount'),
      meta: {
        title: 'Update Account'
      }
    },
    {
      name: 'update_password',
      path: '/account/password',
      component: () => import('@/views/pages/UpdatePassword'),
      meta: {
        title: 'Update Password'
      }
    },
    {
      name: 'reset_password',
      path: '/resetpassword',
      component: () => import('@/views/pages/ResetPassword'),
      meta: {
        title: 'Reset Password'
      }
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/pages/Login'),
      meta: {
        title: 'Account Login'
      }
    },
    {
      name: 'logout',
      path: '/logout',
      component: () => import('@/views/pages/Logout'),
      meta: {
        title: 'Account Logout'
      }
    },
    {
      name: 'view_members',
      path: '/members/view',
      component: () => import('@/views/pages/Members'),
      meta: {
        title: 'View Members'
      }
    },
    {
      name: 'view_member',
      path: '/member/:memberId/view',
      component: () => import('@/views/pages/ViewMember'),
      meta: {
        title: 'View Member'
      }
    },
    {
      name: 'add_member',
      path: '/member/edit',
      component: () => import('@/views/pages/EditMember'),
      meta: {
        title: 'Add Member'
      }
    },
    {
      name: 'edit_member',
      path: '/member/:memberId',
      component: () => import('@/views/pages/EditMember'),
      meta: {
        title: 'Edit Member'
      }
    },
    {
      name: 'add_dependent',
      path: '/members/:memberId/dependent',
      component: () => import('@/views/pages/Dependent'),
      meta: {
        title: 'Edit Dependent'
      }
    },
    {
      name: 'edit_dependent',
      path: '/members/:memberId/dependent/:dependentId',
      component: () => import('@/views/pages/Dependent'),
      meta: {
        title: 'Edit Dependent'
      }
    },
    {
      name: 'add_claim',
      path: '/claim',
      component: () => import('@/views/pages/Claim'),
      meta: {
        title: 'Submit Claim'
      }
    },
    {
      name: 'add_user_claim',
      path: '/members/:memberId/claim',
      component: () => import('@/views/pages/Claim'),
      meta: {
        title: 'Submit Claim'
      }
    },
    {
      name: 'edit_claim',
      path: '/member/:memberId/claim/:claimId',
      component: () => import('@/views/pages/Claim'),
      meta: {
        title: 'Edit Claim'
      }
    },
    // {
    //   name: 'member_claims',
    //   path: '/member/:memberId/claims',
    //   component: () => import('@/views/pages/ViewClaims'),
    //   meta: {
    //     title: 'View Claims'
    //   }
    // },
    {
      name: 'view_claims',
      path: '/claims',
      component: () => import('@/views/pages/Claims'),
      meta: {
        title: 'View Claims'
      }
    },
    {
      name: 'view_claim',
      path: '/member/:memberId/claim/:claimId/view',
      component: () => import('@/views/pages/ViewClaim'),
      meta: {
        title: 'View Claim'
      }
    },
    // Invoices
    {
      name: 'generate_invoice',
      path: '/invoices/generate',
      component: () => import('@/views/pages/Invoice'),
      meta: {
        title: 'Create Invoices'
      }
    },

    {
      name: 'view_invoice',
      path: '/invoices/:invoiceId/view',
      component: () => import('@/views/pages/ViewInvoice'),
      meta: {
        title: 'View Invoice'
      }
    },

    {
      name: 'invoice_report',
      path: '/invoices/report',
      component: () => import('@/views/pages/InvoiceReport'),
      meta: {
        title: 'Invoice Report'
      }
    },

    {
      name: 'invoice_search',
      path: '/invoices/search',
      component: () => import('@/views/pages/InvoiceSearch'),
      meta: {
        title: 'Invoice Search'
      }
    },

    {
      name: 'add_edit_payment',
      path: '/invoices/:invoiceId/payments/:paymentId?',
      component: () => import('@/views/pages/Payment'),
      meta: {
        title: 'Add/Edit Payment'
      }
    },

    {
      name: 'error',
      path: '/error',
      component: () => import('@/views/pages/Error'),
      meta: {
        title: 'Error'
      }
    },

    // Dashboards
    {
      name: 'dashboard_review_members',
      path: '/dashboard/review/members',
      component: () => import('@/views/pages/ReviewMembers'),
      meta: {
        title: 'Review Members'
      }
    },
    {
      name: 'dashboard_review_dependents',
      path: '/dashboard/review/dependents',
      component: () => import('@/views/pages/ReviewDependents'),
      meta: {
        title: 'Review Dependents'
      }
    },
    {
      name: 'dashboard_review_claims',
      path: '/dashboard/review/claims',
      component: () => import('@/views/pages/ReviewClaims'),
      meta: {
        title: 'Review Claims'
      }
    },
    {
      name: 'dashboard_review_payments',
      path: '/dashboard/review/payments',
      component: () => import('@/views/pages/ReviewPayments'),
      meta: {
        title: 'Review Payments'
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
  })
  next()
})

export default router
