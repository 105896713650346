import {mapMutations} from "vuex";

export default {
    /**
     * Handle Authorization response
     * @param responseData
     */
    buildAuthorization(responseData) {
        if (responseData.roles.indexOf('ROLE_ADMIN') === -1) {
            return null
        }

        let currentDate = new Date()
        return {
            userId: responseData.id,
            username: responseData.username,
            roles: responseData.roles,
            token: responseData.token,
            statusId: responseData.status_id,
            tokenExpires: new Date(currentDate.getTime() + (responseData.expires_in * 1000))
        }
    },

    /**
     * Get autho token if still valid
     * @return {null|*}
     */
    getAuthToken() {
        let state = localStorage.getItem('vuex')

        if (!state) {
            return null
        }

        let stateJson = JSON.parse(state)
        if (!stateJson.authorization || !stateJson.authorization.tokenExpires) {
            return null
        }

        let tokenExpires = new Date(stateJson.authorization.tokenExpires)
        if (stateJson && stateJson.authorization.token && tokenExpires > new Date()) {
            return stateJson.authorization.token
        }

        return null
    },

    /**
     * Get user roles
     */
    getRoles() {
        let state = localStorage.getItem('vuex')

        if (!state) {
            return null
        }

        let stateJson = JSON.parse(state)
        if (stateJson.authorization !== undefined && stateJson.authorization.roles !== undefined) {
            return stateJson.authorization.roles
        }
    },

    /**
     * Check if logged in
     * @return {boolean}
     */
    isLoggedIn() {
        const authToken = this.getAuthToken()
        if (authToken === null) {
            localStorage.setItem("authorization", null)
            return false
        }

        return true
    },

    /**
     * Check if has role
     * @param role
     */
    hasRole(role) {
        return this.isLoggedIn() && this.getRoles().indexOf(role) !== -1
    }
}
